/*Styles used to showcase the demo product on ThemeForest and create backgrounds. These can be deleted to increase speed of loading*/
// .bg-0{background-image:url(../images/pictures/0l.jpg);}
// .bg-1{background-image:url(../images/pictures/1.jpg);}
// .bg-2{background-image:url(../images/pictures/2.jpg);}
// .bg-3{background-image:url(../images/pictures/3.jpg);}
// .bg-4{background-image:url(../images/pictures/4.jpg);}
// .bg-5{background-image:url(../images/pictures/5.jpg);}
// .bg-6{background-image:url(../images/pictures/6.jpg);}
// .bg-7{background-image:url(../images/pictures/7.jpg);}
// .bg-8{background-image:url(../images/pictures/8.jpg);}
// .bg-9{background-image:url(../images/pictures/9.jpg);}
// .bg-10{background-image:url(../images/pictures/10.jpg);}
// .bg-11{background-image:url(../images/pictures/11.jpg);}
// .bg-12{background-image:url(../images/pictures/12.jpg);}
// .bg-13{background-image:url(../images/pictures/13.jpg);}
// .bg-14{background-image:url(../images/pictures/14.jpg);}
// .bg-15{background-image:url(../images/pictures/15.jpg);}
// .bg-16{background-image:url(../images/pictures/16.jpg);}
// .bg-17{background-image:url(../images/pictures/17.jpg);}
// .bg-18{background-image:url(../images/pictures/18.jpg);}
// .bg-19{background-image:url(../images/pictures/19.jpg);}
// .bg-20{background-image:url(../images/pictures/20.jpg);}
// .bg-21{background-image:url(../images/pictures/21.jpg);}
// .bg-22{background-image:url(../images/pictures/22.jpg);}
// .bg-23{background-image:url(../images/pictures/23.jpg);}
// .bg-24{background-image:url(../images/pictures/24.jpg);}
// .bg-25{background-image:url(../images/pictures/25.jpg);}
// .bg-26{background-image:url(../images/pictures/26.jpg);}
// .bg-27{background-image:url(../images/pictures/27.jpg);}
// .bg-28{background-image:url(../images/pictures/28.jpg);}
// .bg-29{background-image:url(../images/pictures/29.jpg);}
// .bg-30{background-image:url(../images/pictures/30.jpg);}
// .bg-31{background-image:url(../images/pictures/31.jpg);}
// .bg-32{background-image:url(../images/pictures/32.jpg);}
// .bg-33{background-image:url(../images/pictures/33.jpg);}
// .bg-34{background-image:url(../images/pictures/34.jpg);}

.bg-8{background-image:url(../../images/sliders/8.jpeg);}
.bg-14{background-image:url(../../images/sliders/14.jpg);}
.bg-17{background-image:url(../../images/sliders/17.jpg);}

/*Demo Colors*/
.demo-color{
    font-family:$headings-font-family;
    display:block;
    width:100%;
    line-height:45px;
    padding-left:10px;
    text-transform:capitalize;
    border-bottom:solid 1px rgba(255,255,255,0.05);
}

.demo-color strong{display:block; font-size:9px; margin-top:-25px; padding-bottom:3px; font-weight:400; opacity:0.8;}

.demo-color span{
    font-size:10px;
    position:absolute;
    right:20px;
    line-height:48px;
    color:rgba(255,255,255,0.5);
}


/*Highlight Changer*/
.theme-change-transition{
    transition:all 150ms ease!important;
}


/*Highlight Changer*/
.theme-change-transition{
    transition:all 150ms ease!important;
}
.highlight-changer a{
    width:20%;
    float:left;
    text-align:center;
    line-height:42px;
    font-size:20px;
    margin-top:10px;
    margin-bottom:-5px;
}
.highlight-changer a i{margin-top:10px; display:block; font-size:26px!important;}
.highlight-changer a span{
    font-size:11px;
    display:block;
    margin-top:-10px;
    margin-bottom:-5px;
}

.background-changer a{
    width:20%;
    float:left;
    text-align:center;
    line-height:42px;
    font-size:20px;
    margin-top:10px;
    margin-bottom:-5px;
}
.background-changer a i{
    margin-top:10px; 
    display:block; 
    width:26px;
    height:26px;
    border-radius:100%;
    color:rgba(255,255,255,0);
    margin:10px auto 0px auto;
}
.background-changer .bg-theme{
    border:solid 1px rgba(0,0,0,0.2);
}
.background-changer a span{
    font-size:11px;
    display:block;
    margin-top:-10px;
    margin-bottom:-5px;
}