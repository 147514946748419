
/*--Header---*/
.header-title {
	span {
		font-family: $font-heading-family;
	}
}
.footer-title {
	span {
		font-family: $font-heading-family;
	}
}
.header-logo {
	span {
		display: none;
		display: none;
	}
	color: $color-transparent !important;
}
.header {
    .header-subtitle{
        font-size:12px!important;
        font-weight:600!important;
    }
	.header-title {
		position: absolute;
		height: 55px;
		line-height: 55px;
		font-size: 15px;
		color: $color-black;
		font-weight: 800;
		z-index: 1;
		font-family: $font-heading-family;
	}
	.header-logo {
		background-repeat: no-repeat;
		background-position: center center;
		height: 100%;
		position: absolute;
		z-index: 1;
		left: 50%;
	}
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 55px;
	z-index: 99;
	transition: $globalTransitions;
	background-color: $color-white;
	border-bottom: solid 1px rgba(0,0,0,0.03);
	box-shadow: 0 4px 18px 0 rgba(0,0,0,0.08);
	.header-icon {
		position: absolute;
		height: 55px;
		width: 45px;
		text-align: center;
		line-height: 54px;
		font-size: 12px;
		color: $color-dark;
		border: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: $color-transparent !important;
	}
	.dropdown-menu {
		z-index: 99;
	}
}
.header-nav {
	.header-title {
		position: absolute;
		height: 55px;
		line-height: 55px;
		font-size: 14px;
		color: $color-black;
		font-weight: 600;
		z-index: 1;
		font-family: $font-heading-family;
	}
	.header-logo {
		background-repeat: no-repeat;
		background-position: center center;
		height: 100%;
		position: absolute;
		z-index: 1;
		left: 50%;
	}
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 55px;
	z-index: 99;
	transition: $globalTransitions;
	.header-icon {
		position: absolute;
		height: 55px;
		width: 45px;
		text-align: center;
		line-height: 54px;
		font-size: 12px;
		color: $color-dark;
		border: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: $color-transparent !important;
	}
}
.footer {
	.footer-logo {
		background-repeat: no-repeat;
		background-position: center center;
		height: 100%;
		position: absolute;
		z-index: 1;
		left: 50%;
	}
}
.header-auto-show {
	opacity: 0;
    pointer-events:none;
	transition: $globalTransitions;
}
.header-active {
	opacity: 1;
    pointer-events: all!important;
	transition: $globalTransitions;
}
.header-always-show {
	.header {
		margin-top: 0px !important;
	}
}
.header-static {
	position: relative !important;
}
.header-transparent {
	background-color: $color-transparent !important;
	box-shadow: none !important;
	border: none !important;
}
.header-icon {
	.badge {
		position: absolute;
		margin-left: -5px;
		color: $color-white !important;
		width: 16px;
		text-align: center;
		line-height: 16px;
		padding: 0px;
		padding-left: 1px !important;
		border-radius: 18px;
		margin-top: 7px;
		font-size: 11px;
	}
}
.header-transparent-light {
	background-color: $color-transparent;
	box-shadow: none !important;
	a {
		color: $color-white;
	}
}
.header-transparent-dark {
	background-color: $color-transparent;
	box-shadow: none !important;
	a {
		color: $color-black;
	}
}
.header-logo-center {
	.header-icon-1 {
		left: 0px;
	}
	.header-icon-2 {
		left: 45px;
	}
	.header-icon-3 {
		right: 45px;
	}
	.header-icon-4 {
		right: 0px;
	}
	.header-title {
		width: 150px;
		left: 50%;
		margin-left: -75px;
		text-align: center;
	}
	.header-logo {
		background-position: center center;
		width: 150px !important;
		left: 50%;
		margin-left: -75px;
		text-align: center;
	}
}
.header-logo-left {
	.header-icon-1 {
		right: 0px;
	}
	.header-icon-2 {
		right: 45px;
	}
	.header-icon-3 {
		right: 90px;
	}
	.header-icon-4 {
		right: 135px;
	}
	.header-logo {
		left: 0px !important;
		margin-left: 0px !important;
	}
	.header-title {
		left: 15px !important;
		margin-left: 0px !important;
	}
}
.header-logo-right {
	.header-icon-1 {
		left: 0px;
	}
	.header-icon-2 {
		left: 45px;
	}
	.header-icon-3 {
		left: 90px;
	}
	.header-icon-4 {
		left: 135px;
	}
	.header-logo {
		left: auto !important;
		right: 20px !important;
		margin-left: 0px !important;
	}
	.header-title {
		left: auto !important;
		right: 20px !important;
		margin-left: 0px !important;
	}
}
.header-logo-app {
	.header-icon-1 {
		left: 3px;
	}
	.header-icon-2 {
		right: 2px;
	}
	.header-icon-3 {
		right: 45px;
	}
	.header-icon-4 {
		right: 90px;
	}
	.header-logo {
		left: 0px !important;
		margin-left: 40px !important;
	}
	.header-title {
		left: 0px !important;
		margin-left: 55px !important;
	}
}
.footer-logo {
	span {
		display: none;
	}
}
.header-demo {
	z-index: 98 !important;
	position: relative;
}

.header-clear {
    padding-top:calc(55px + (constant(safe-area-inset-top))*0.75)!important;
    padding-top:calc(55px + (env(safe-area-inset-top))*0.75)!important;
}
.header-clear-small {
    padding-top:calc(5px + (constant(safe-area-inset-top))*0.75)!important;
    padding-top:calc(5px + (env(safe-area-inset-top))*0.75)!important;
}
.header-clear-medium {
    padding-top:calc(60px + (constant(safe-area-inset-top))*0.75)!important;
    padding-top:calc(60px + (env(safe-area-inset-top))*0.75)!important;
}
.header-clear-large {
    padding-top:calc(80px + (constant(safe-area-inset-top))*0.75)!important;
    padding-top:calc(80px + (env(safe-area-inset-top))*0.75)!important;
}
.header {
    height:calc(55px + (constant(safe-area-inset-top))*0.75)!important;
    height:calc(55px + (env(safe-area-inset-top))*0.75)!important;
}
.header-icon {
    margin-top:calc(0px + (constant(safe-area-inset-top))*0.75)!important;
    margin-top:calc(0px + (env(safe-area-inset-top))*0.75)!important;
}
.header-title {
    margin-top:calc(0px + (constant(safe-area-inset-top))*0.75)!important;
    margin-top:calc(0px + (env(safe-area-inset-top))*0.75)!important;
}

.page-content .page-title{
    margin-top:calc(20px + (constant(safe-area-inset-top))*0.75)!important;
    margin-top:calc(20px + (env(safe-area-inset-top))*0.75)!important;
}

//Azures v3.6 Header Fix
.header-auto-show{pointer-events:none;}
.header-auto-show.header-active{pointer-events:all;}