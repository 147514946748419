.menu-header{
    display:flex;
    margin-bottom:$globalMargin*1.5;
}

.menu-header a{
    flex: 1 1 auto;
    text-align:center;
    line-height:50px;
    border:solid 1px rgba(0,0,0,0.03);
}

.menu-items h5{
    padding-left:15px;
}

.menu-items{margin-bottom:40px;}
.menu-items a{display:block; line-height:50px; border-bottom:solid 1px rgba(0,0,0,0.04);}
.menu-items a:last-child{border-bottom:none;}

.menu-items a i:first-child{
    position:absolute;
    width:55px;
    height:50px;
    line-height:51px;
    text-align:center;
    font-size:14px;
}

.menu-items a svg{
    margin-top:17px;
    position:absolute;
    margin-left:20px;
    line-height:50px;
    text-align:center;
    font-size:14px;
}

.menu-items span{
    color:#1f1f1f;
    padding-left:60px;
    line-height:50px;
    font-size:13px;
    transition:all 250ms ease;
}

.submenu{overflow:hidden!important; backface-visibility: hidden!important; transform:Translate3d(0px, 0px, 0px);}

.menu-items i:last-child{
    color:#434343;
    opacity:0.3;
    position:absolute;
    right:5px;
    transform:scale(0.7,0.7);
    text-align:center;
    line-height:50px;
    width:50px;
    transition:all 250ms ease;
    font-size:8px!important;
}

.menu-items em{
    position:absolute;
    right:15px;
    line-height:14px;
    font-size:9px;
    padding:2px 6px;
    text-align:center;
    margin-top:18px;
    font-style: normal;
    font-family: 'Roboto', sans-serif;
    font-weight:500;
    z-index:2;
    border-radius:4px;
}

.menu-items strong{
    position:absolute;
    right:21px;
    width:19px;
    height:19px;
    line-height:14px;
    font-size:10px;
    text-align:center;
    margin-top:18px;
    font-style: normal;
    font-family: 'Roboto', sans-serif;
    font-weight:500;
    z-index:2;
    border-radius:11px;
}

.nav-item-active span{
    opacity:1!important;
    font-size:13px;
    font-weight:700!important;
    transition:all 250ms ease;
}
.submenu .nav-item-active span{font-size:13px!important;}
.nav-item-active .fa-circle{opacity:1!important; transform:scale(1,1)!important; color:#8CC152!important;}

[data-submenu] .badge{line-height:11px!important; font-size:10px!important; margin-top:16px}
.submenu{height:0px;}
.submenu a:first-child{padding-top:10px;}
.submenu a{margin-top:-1px; border:none;}
.submenu a i{transform:scale(0.7, 0.7);}
.submenu a span{margin-left:0px; font-size:12px; opacity:0.7;}
.submenu a:last-child{padding-bottom:15px; border-bottom:solid 1px rgba(0,0,0,0.05);}

#menu-main{
    top:10px!important;
    bottom:10px!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
}