.bg-image-avatar-default {
  background-image: url(../../images/avatar.png);
}

.bg-image-avatar-female-default {
  background-image: url(../../images/avatar-female.png);
}

.bg-header-footer-default {
  background-image: url(../../images/bg-header-footer.jpg);
}

.upperDiv {
  height: 97vh;
  background-image: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  padding-top: 26px;
}

.sliderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle {
  position: absolute;
  left: 0px;
  transform: translate3d(32px, 0px, 0px) !important;
}

.theme-dark {
  .react-datepicker__tab-loop {
    .react-datepicker {
      background-color: #0f1116;
      border-color: #222428;
    }
  }
  .react-datepicker__header {
    background-color: #0f1116;
    border-color: #222428;
  }
  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__current-month {
    color: #ffffff;
  }
  .react-datepicker__triangle::before {
    border-bottom-color: #222428 !important;
  }
  .react-datepicker__triangle::after {
    border-bottom-color: #0f1116 !important;
  }
  .react-datepicker__day:hover {
    background-color: #525252;
  }
  .react-datepicker__day--disabled {
    color: #525252;
  }
  .react-datepicker__day--disabled:hover {
    background-color: transparent;
  }
}
.theme-light {
  .react-datepicker__tab-loop {
    .react-datepicker {
      background-color: #ffffff;
      border-color: #ebebeb;
    }
  }
  .react-datepicker__header {
    background-color: #ffffff;
    border-color: #ebebeb;
  }
  .react-datepicker__triangle::before {
    border-bottom-color: #ebebeb !important;
  }
  .react-datepicker__triangle::after {
    border-bottom-color: #ffffff !important;
  }
  .react-datepicker__header {
    background-color: #ffffff !important;
  }
}
.react-datepicker__day--selected {
  background-color: #80b04d;
}

.react-datepicker__navigation-icon--next::before {
  left: 0px;
}

.close-menu.text-confirm {
  background-color: #da4453 !important;
}

.close-menu.text-cancel {
  background-color: #8cc152 !important;
}

.nama-siswa {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}
