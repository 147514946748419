/*PageApp Calendar*/
.calendar {
	position: relative;
	background-color: $color_white;
	border-radius: 5px;
	overflow: hidden;
	max-width: 90%;
	margin: 0px auto 30px auto;
}
.cal-title {
	color: $color_white;
	line-height: 60px;
	margin-bottom: 0px;
	padding: 0px 15px;
}
.cal-sub-title {
	color: $color_white;
	line-height: 60px;
	margin-bottom: 0px;
	padding: 0px 15px;
}
.cal-title-right {
	float: right;
	margin-top: -60px;
	line-height: 62px;
	height: 50px;
	padding-right: 20px;
}
.cal-title-left {
	float: left;
	margin-top: -60px;
	line-height: 62px;
	height: 50px;
	padding-left: 20px;
}
.cal-days {
	a {
		position: relative;
		width: 14.28%;
		float: left;
		text-align: center;
		height: 35px;
		line-height: 35px;
		color: $color_white;
		position: realtive;
		z-index: 3;
		font-weight: 800;
	}
}
.cal-disabled {
	color: $gray-400 !important;
}
.cal-selected {
	span {
		z-index: 2;
		position: relative;
		color: $color_white;
	}
	i {
		color: $color_black;
		z-index: 0;
		opacity: 1;
		font-size: 32px;
		position: absolute;
		width: 32px;
		height: 32px;
		top: 50%;
		left: 50%;
		margin:-16px 0px 0px -16px;
		text-align: center;
		line-height: 32px;
	}
}
.cal-dates {
	a {
        position:relative;
		width: 14.2857142857%;
		float: left;
		text-align: center;
		line-height: 45px;
		color: $color_dark;
		font-weight: 500;
	}
}
.theme-dark .cal-dates a{color:$color_white;}
.theme-dark .cal-disabled{opacity:0.3;}
.cal-dates-border {
	a {
		overflow: hidden;
		border-right: solid 1px rgba(0,0,0,0.05);
		border-bottom: solid 1px rgba(0,0,0,0.05);
	}
}
.cal-schedule {
	min-height: 80px;
	border-bottom: solid 1px rgba(0,0,0,0.055);
	em {
		line-height: 20px;
		padding: 20px 0px 0px 20px;
		position: absolute;
		font-size: 11px;
		font-style: normal;
	}
	strong {
        color:$color_black;
		display: block;
		padding: 17px 0px 0px 100px;
		font-size: 14px;
	}
	span {
		display: block;
		font-size: 10px;
		color: $gray-500;
		margin-top: 0px;
		i {
			width: 50px;
			display: inline-block;
			text-align: center;
			padding: 0px 20px 0px 102px;
		}
	}
}
.theme-dark .cal-schedule strong{color:$color_white!important;}
.theme-dark .cal-schedule span{color:$color_white!important; opacity:0.5;}
.cal-message {
	i {
		position: absolute;
		height: 40px;
		line-height: 43px;
		font-size: 27px;
		right: 20px;
	}
	display: block;
	strong {
		display: block;
		line-height: 20px;
		font-weight: 400;
		font-size: 11px;
		padding-left: 20px;
	}
}
