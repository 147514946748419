/*Collection*/
.gallery-collection-round a img{border-radius:10px;}
.gallery-collection a{width:48%; float:left; position:relative;}
.gallery-collection a:nth-child(2n+2){margin-left:4%; margin-bottom:4%;}
.gallery-collection a img{width:50%; float:left; padding:1px;}
.gallery-collection i{
	position:absolute;
	width:50px;
	height:50px;
	line-height:50px;
	left:50%;
	top:50%;
	z-index:10;
	margin-top:-25px;
	margin-left:-25px;
	text-align:center;
	background-color:#FFFFFF;
	border-radius:50px;
	font-size:17px;
	color:#1f1f1f;
}

/*Gallery Views*/

.gallery-view-controls a{
    float:left;
    width:33.3%;
    text-align:center;
    height:50px;
    line-height:50px;
    font-size:16px;
    color:#1f1f1f;
}
.theme-dark .gallery-view-controls a{color:#FFF;}
.theme-dark .gallery-view-controls{border-bottom:solid 1px rgba(255,255,255,0.07);}

.gallery-view-controls{border-bottom:solid 1px rgba(0,0,0,0.05); margin-bottom:30px;}
.gallery-view-1 a{width:30%; float:left;}
.gallery-view-1 .caption{display:none;}
.gallery-view-2 .caption{display:none;}
.gallery-view-3 .caption{padding-top:20px;}
.gallery-view-1 a{margin-bottom:5%;}
.gallery-view-1 a:nth-child(3n-1){margin:auto 5% auto 5%;}
.gallery-view-2 a{width:47.5%; margin-bottom:5%; float:left;}
.gallery-view-2 a:nth-child(2n-1){margin-right:5%;}



