/*Tabs*/
.tab-content {
//	position: relative;
//	-webkit-transform: translate3d(0,0,0);
//    overflow:hidden;
//    transition:all 250ms ease;
}


.tab-controls{
    display:flex;
}

.tab-controls a{
    font-family:$font-heading-family;
    color: $color_dark;
    background-color:$color_white;
    width:100%;
    text-align:center;
    border-right:solid 0px!important;
    border: solid 1px rgba(0,0,0,0.07);
    align-self: center;
    margin:0 auto;
    &:last-child {
        border-right: solid 1px rgba(0,0,0,0.07)!important;
    }
}

.tabs-small{line-height:35px;}
.tabs-medium{line-height:40px;}
.tabs-large{line-height:45px;}
.tabs-rounded a:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.tabs-rounded a:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}