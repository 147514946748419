/*Icons*/
.icon:hover{color:#FFF;}
.icon{display:inline-block; position:relative; text-align:center;}
.icon-xxs i{width:$icon-xxs; line-height:$icon-xxs; font-size:13px;}
.icon-xs i{width:$icon-xs; line-height:$icon-xs; font-size:14px;}
.icon-s i{width:$icon-s; line-height:$icon-s; font-size:15px;}
.icon-m i{width:$icon-m; line-height:$icon-m; font-size:16px;}
.icon-l i{width:$icon-l; line-height:$icon-l; font-size:17px;}
.icon-xl i{width:$icon-xl; line-height:$icon-xl; font-size:18px;}
.icon-xxl i{width:$icon-xxl; line-height:$icon-xxl; font-size:19px;}
.icon-center{display:block; margin:0 auto;}

.icon-20{width:20px;}
.icon-30{width:30px;}
.icon-40{width:40px;}
.icon-50{width:50px;}
.icon-60{width:60px;}
.icon-70{width:70px;}
.icon-80{width:80px;}
.icon-90{width:90px;}