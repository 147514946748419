/*-Pageapp Chat-*/
.speech-left {
	max-width: 200px;
    float:right;
	border-bottom-right-radius: 0px !important;
	color: $color_white;
}
.speech-right {
    float:left;
	max-width: 200px;
	border-bottom-left-radius: 0px !important;
}
.speach-image {
	padding: 0px !important;
	img {
		margin: 0px;
	}
}
.speech-last {
	margin-bottom: 0px !important;
}
.speech-bubble {
	position: relative;
	border-radius: 17px;
	padding: 10px 15px;
	margin-bottom: 15px;
	line-height: 22px;
	font-size: 13px;
	background-color: $gray-300;
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}
.speech-read {
	font-size: 10px;
	font-style: normal;
	display: block;
	text-align: right;
	font-weight: 500;
	color: $gray-500;
	margin-bottom: 80px;
}
.speach-input {
	input {
		width: 100%;
		display: block;
		line-height: 45px;
		height: 45px;
		margin-top: 8px;
		border-radius: 45px;
	}
}
.speach-icon {
	a {
		padding-top: 0px !important;
		display: block;
		border-radius: 35px;
		width: 35px;
		height: 35px;
		line-height: 35px;
		margin-top: 3px;
		transform: translateY(10px);
	}
}

.speech-icon-left, .speech-icon-right{position:relative;  display:block;}

.speech-icon-left img,
.speech-icon-right img{
    width:30px;
    position:absolute;
    bottom:0px;
}

.speech-icon-left img{left:0px;}
.speech-icon-right img{right:0px;}

.speech-icon-left .speech-bubble{margin-left:20px!important;}
.speech-icon-right .speech-bubble{margin-right:20px!important;}