/*Page VCard*/
.vcard-title{
	font-weight:600;
	font-size:18px;
	text-transform:uppercase;
}
.vcard-field{
    line-height:50px;
    border-left:0px!important; 
    border-top:0px!important; 
    border-right: 0px!important; 
    padding-top:15px; 
    border-bottom:solid 1px rgba(0,0,0,0.05);
    position: relative;
}
.vcard-field strong{
    position:absolute;
    margin-top:-18px;
	color:#ababab;
	font-weight:400;
	font-size:10px;
}
.vcard-field i{
	position:absolute;
	top:25px;
	width:15px;
	text-align:center;
	right:0px;
	opacity:0.3;
}
.vcard-field a{
	color:#000;
	font-weight:400;
	font-size:14px;
	margin-top:-10px;
	margin-bottom:10px;
    opacity:0.7;
}

